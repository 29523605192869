import { Application, Controller } from "stimulus"

export default class ScrollableContainerController extends Controller {
  
  scrollMax = 0;
  currentScroll = 0;
  scrollDistance = 250;

  static targets = [ "scrollableContainer", "scrollableContent", "navLeft", "navRight" ]
  static values = { "navigationArrows": Boolean}
  
  scrollLeft() {
    if (this.currentScroll > 0) {
      this.currentScroll = Math.max(this.currentScroll - this.scrollDistance, 0);
      this.scrollableContentTarget.scrollTo({left: this.currentScroll})
    }
    this.checkNavs();
    this.checkGradients();
  }

  scrollRight() {
    this.scrollMax = this.scrollableContentTarget.scrollWidth - this.scrollableContentTarget.offsetWidth;
    if (this.currentScroll < this.scrollMax) {
      this.currentScroll = Math.min(this.currentScroll + this.scrollDistance, this.scrollMax);
      this.scrollableContentTarget.scrollTo({left: this.currentScroll})
    }
    this.checkNavs();
    this.checkGradients();
  }

  mouseScroll() {
    this.currentScroll = this.scrollableContentTarget.scrollLeft;
    this.checkNavs();
    this.checkGradients();
  }

  checkNavs() {
    if (this.navigationArrowsValue) {
      this.currentScroll > 0 ? this.navLeftTarget.classList.remove("hidden") : this.navLeftTarget.classList.add("hidden");
      this.currentScroll == this.scrollMax ? this.navRightTarget.classList.add("hidden") : this.navRightTarget.classList.remove("hidden");
    }
  }

  checkGradients() {
    this.currentScroll > 0 ? this.scrollableContainerTarget.classList.add("scrollable-container-gradient-left") : this.scrollableContainerTarget.classList.remove("scrollable-container-gradient-left");
  }

}

const application = Application.start()
application.register("scrollable-container", ScrollableContainerController)
