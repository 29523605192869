import { Application, Controller } from "stimulus"

export default class AddPlaceSuggestedPlacesController extends Controller {
  
  static targets = ["suggestedPlace", "breadcrumbPlace", "nearPlacesSection"]
  static values = {previewLandingPage: Boolean}

  suggestedPlacesObserver = this.suggestedPlacesObserver();
  onTopPage = window.scrollY < 500;
  connect() {
    window.addPlaceSuggestedPlacesController = this;
    if (!this.previewLandingPageValue) {
      this.initializeEvents();
    }
  }

  initializeEvents() {
    this.shouldDelayLoadingSuggestedPlaceImages() ? this.addObservers() : this.appendSuggestedPlacesImages()
  }

  addObservers() {
    if (this.hasBreadcrumbPlaceTarget) {
      this.suggestedPlacesObserver.observe(this.breadcrumbPlaceTarget)
    }
    if (this.hasNearPlacesSectionTarget) {
      this.suggestedPlacesObserver.observe(this.nearPlacesSectionTarget);
    }
  }

  removeObservers() {
    if (this.hasBreadcrumbPlaceTarget) {
      this.suggestedPlacesObserver.unobserve(this.breadcrumbPlaceTarget)
    }
    if (this.hasNearPlacesSectionTarget) {
      this.suggestedPlacesObserver.unobserve(this.nearPlacesSectionTarget);
    }
  }

  suggestedPlacesObserver() {
    return new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0.2) {
          this.appendSuggestedPlacesImages();
          this.removeObservers();
        }
      });
    }, { threshold: 0.25 });
  }

  appendSuggestedPlacesImages() {
    this.suggestedPlaceTargets.forEach(suggestedPlace => {
      const imgStyleUrl = `url(${suggestedPlace.dataset.addPlaceSuggestedPlacesImageUrl})`
      suggestedPlace.style.backgroundImage = imgStyleUrl;
    });
  }

  shouldDelayLoadingSuggestedPlaceImages() {
    return this.onTopPage && (this.hasBreadcrumbPlaceTarget || this.hasNearPlacesSectionTarget)
  }
}

const application = Application.start()
application.register("add-place-suggested-places", AddPlaceSuggestedPlacesController)
